import { GetServerSideProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import HomePage from "@components/layout/Home";
import { Meta } from "@components/common/Meta";
import PageLayout from "@components/layout/CommonPageLayout";
import { getChooseUs } from "@api/get-choose-us.api";
import { AppLanguage } from "@common/enum/enum";
import { getAds } from "@api/get-ads.api";
import { getFaq } from "@api/get-faq.api";
import { getMainPageStatistics } from "@api/get-main-page-statistics.api";
import {
  AdsOut,
  ChooseUsTextOut,
  CryptoPair,
  FaqOut,
  GlobalContextKind,
  MainPageStatistics,
} from "@common/type/type";
import { useContext, useEffect } from "react";
import { getCryptoOnUrl } from "@utils/getCryptoOnUrl";
import { EMPTY_CRYPTO } from "@common/constant/InitialPair.constant";
import { GlobalContext } from "@context/GlobalContext";

type Props = {
  chooseUsData: Array<ChooseUsTextOut>;
  adsDataList: Array<AdsOut>;
  statisticsData: MainPageStatistics;
  faqDataList: Array<FaqOut>;
};

function Home({
  chooseUsData,
  adsDataList,
  statisticsData,
  faqDataList,
}: Props) {
  const { t } = useTranslation(["seo"]);
  const { dispatch, state } = useContext(GlobalContext);

  useEffect(() => {
    if (!state?.crypto?.length) {
      getCryptoOnUrl().then(res => {
        const initialPair: CryptoPair = [
          res.find(
            ({ abbreviation }) => abbreviation?.toLowerCase() === "usdttrc20"
          ) || res[0],
          EMPTY_CRYPTO,
        ];

        dispatch({
          type: GlobalContextKind.CRYPTO,
          payload: res,
        });
        dispatch({
          type: GlobalContextKind.PAIRS,
          payload: initialPair,
        });
      });
    }
  }, []);

  return (
    <>
      <Meta title={t("homeTitle")} description={t("homeDescription") || ""} />
      <PageLayout>
        <HomePage
          homeData={{ chooseUsData, adsDataList, statisticsData, faqDataList }}
        />
      </PageLayout>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  defaultLocale,
}) => {
  const lang = (locale || defaultLocale) as AppLanguage;
  const chooseUsPromise = getChooseUs(lang);
  const adsPromise = getAds(lang);
  const statisticsPromise = getMainPageStatistics();
  const faqPromise = getFaq(lang);

  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", [
        "home",
        "header",
        "footer",
        "monitoring",
        "changerInfo",
        "form",
        "common",
        "statistics",
        "tooltip",
        "exchangersPage",
        "seo",
      ])),
      chooseUsData: await chooseUsPromise,
      adsDataList: await adsPromise,
      statisticsData: await statisticsPromise,
      faqDataList: await faqPromise,
    },
  };
};

export default Home;
