import { getAllCurrency } from "@api/get-all-currency.api";
import { CurrencyCategory } from "@common/enum/enum";
import { CryptoType, CurrencyOut } from "@common/type/type";

export const getCryptoOnUrl = async () => {
  const data: CurrencyOut[] = await getAllCurrency();

  const cryptoData: CryptoType[] = data.reduce<CryptoType[]>((acc, item) => {
    acc.push({
      id: item.id,
      abbreviation: item.short_name,
      name: item.name,
      image: item.logo_url || "",
      category: item.currency_category as CurrencyCategory,
    });
    return acc;
  }, []);

  const cryptoMap = cryptoData.reduce((map, crypto) => {
    const { abbreviation } = crypto;
    const cryptoKey = abbreviation.toLowerCase();
    const existedCrypto = map.get(cryptoKey);

    if (!existedCrypto) {
      return map.set(cryptoKey, crypto);
    }

    if (!existedCrypto.image && crypto.image) {
      return map.set(cryptoKey, crypto);
    }

    return map;
  }, new Map<string, CryptoType>());

  return Array.from(cryptoMap, ([, value]) => value);
};
